import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useConfigManager } from 'config/useConfigManager';
import {
  useAppDispatch,
  useAppSelector,
  useAuthRedirect,
  useConfirmationToast,
  useErrorMessage,
  useNavigateWithRedirect,
} from 'hooks';
import { LoginMethod, LoginStatus } from 'models';
import { authActions, getAccountLoginMethodAction } from 'store';
import { emailRegex } from 'utils/validationRegex';
import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithRedirect();
  const [searchParams] = useSearchParams();
  const { loginStatus, loginMethod } = useAppSelector((state) => state.auth);
  const email = useAppSelector((state) => state.auth.accountInfo.username) || searchParams.get('email');
  const isSocialLogin = searchParams.get('is_social_login')?.toLowerCase() === 'true';
  const configManager = useConfigManager();
  const redirectUri = searchParams.get('redirect_uri') || configManager.get('defaultRedirectUri');
  const { errorMessage, errorCode } = useErrorMessage((state) => state.auth.error);
  const loginHint = searchParams.get('login_hint');

  useAuthRedirect();

  useConfirmationToast({
    errorProps: {
      icon: 'WarningIcon',
      message: errorMessage,
      errorCode,
    },
    resetAction: () => {
      dispatch(
        authActions.setError({
          isError: false,
        }),
      );
    },
    showError: errorMessage !== '',
  });

  useEffect(() => {
    const username = email || loginHint;

    if (!username || loginStatus !== LoginStatus.ReadyForLogin || loginMethod !== LoginMethod.NotSet || isSocialLogin) {
      return;
    }

    const rgx = new RegExp(emailRegex);
    if (!rgx.test(username)) {
      return;
    }

    dispatch(getAccountLoginMethodAction({ username, redirectUri }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, loginStatus, loginMethod]);

  useEffect(() => {
    if (loginHint && !email && loginMethod === LoginMethod.UsernameAndPassword) {
      searchParams.delete('login_hint');
      searchParams.set('email', loginHint);
      navigate({ pathname: '/', search: searchParams.toString() }, { replace: true });
    }
  }, [email, loginHint, loginMethod, searchParams, navigate]);

  return loginMethod === LoginMethod.UsernameAndPassword && email ? <PasswordForm /> : <EmailForm />;
};

export default Login;
