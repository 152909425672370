import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Stack, TextFormInput, TextPrimary, TextSecondary, themeSpacings } from '@teamviewer/ui-library';

import { Banner, Divider, IconLink, Modal, ModalBanner, ModalMain, SocialLoginButton, SubmitButton } from 'components';
import { IButtonType } from 'components/SocialLoginButton/SocialLoginButton';
import { useConfigManager } from 'config/useConfigManager';
import { useInitialServiceConfig } from 'config/useInitialServiceConfig';
import { useAppDispatch, useAppSelector, useIsBackButtonVisible } from 'hooks';
import { useStayTunedDialog } from 'hooks/use-stay-tuned-dialog';
import { ClientType, LoginStatus } from 'models';
import { authActions } from 'store';
import { useLinkStyles } from 'utils/commonStyles';
import { splitAndStyleString, splitAndStyleStringWithClickHandler } from 'utils/stringUtils';
import { emailRegex } from 'utils/validationRegex';
import { getEmailValidationRules } from 'utils/validationRules';

import { useStyles } from './EmailForm.styles';

export const EmailForm = () => {
  const configManager = useConfigManager();
  const featureFlags = configManager.get('featureFlags');
  const { t } = useTranslation(['login', 'validation', 'common']);
  const dispatch = useAppDispatch();
  const { render: renderStayTunedDialog, toggle: toggleStayTunedDialog } = useStayTunedDialog();
  const [searchParams] = useSearchParams();
  const { clientType } = useInitialServiceConfig();
  const isNativeClient = clientType === ClientType.NativeClient;
  const isBackButtonVisible = useIsBackButtonVisible();
  const backURLClient = useMemo(() => computeBackURL({ isNativeClient, searchParams }), [isNativeClient, searchParams]);
  const emailParam = searchParams.get('email');
  const isOauthSocialLogin = searchParams.get('is_social_login')?.toLowerCase() === 'true';
  const redirectUri = searchParams.get('redirect_uri') || configManager.get('defaultRedirectUri');
  const isOauth = redirectUri.includes('oauth2');
  const isLoading = useAppSelector((state) => state.auth.isLoginInProgress) || (isOauth && isOauthSocialLogin);
  const isCheckmarkVisible = false;
  const { linkStyles } = useLinkStyles();
  const {
    buttonStackStyles,
    createAccountSectionStyles,
    formSectionStyles,
    socialLoginStyles,
    subtitleStyles,
    titleStyles,
  } = useStyles();

  const loginHint = useMemo(() => {
    const rgx = new RegExp(emailRegex);
    const loginHintParam = searchParams.get('login_hint');

    if (loginHintParam && rgx.test(loginHintParam)) {
      return loginHintParam;
    }
  }, [searchParams]);

  const {
    control,
    formState: { isValid, dirtyFields, submitCount },
    handleSubmit,
  } = useForm<any>({
    defaultValues: { email: emailParam || loginHint },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = (e: any) => {
    e.preventDefault();

    handleSubmit((data) => {
      if (loginHint && data.email !== loginHint) {
        dispatch(authActions.setEmailAndResetLogin(data.email));
      } else {
        dispatch(authActions.setUsername(data.email));
        dispatch(authActions.setLoginStatus(LoginStatus.ReadyForLogin));
      }
    })();
  };

  return (
    <Modal>
      <ModalMain>
        {renderStayTunedDialog()}
        <Stack>
          {isBackButtonVisible ? (
            <IconLink
              iconName="Back"
              link=""
              onClick={() => {
                window.location.assign(backURLClient);
              }}
              disabled={isLoading}
              tabIndex={1}
              data-testid="signin-go-back-link"
              as="TextButton"
            >
              <TextPrimary as="h1" block data-testid="signin-email-title" className={titleStyles}>
                {t('title')}
              </TextPrimary>
            </IconLink>
          ) : (
            <TextPrimary as="h1" block data-testid="signin-email-title" className={titleStyles}>
              {t('title')}
            </TextPrimary>
          )}

          <TextSecondary variant="medium" className={subtitleStyles} block data-testid="signin-email-subtitle">
            {t('subtitle')}
          </TextSecondary>
        </Stack>
        <form data-testid="signin-email-form" noValidate onSubmit={onSubmit}>
          <Stack className={formSectionStyles} tokens={{ childrenGap: themeSpacings.m }}>
            <TextFormInput
              id="email-field"
              name="email"
              type="email"
              control={control}
              inputMode="email"
              rules={getEmailValidationRules(t)}
              autoComplete="email"
              label={t('common:email')}
              ariaLabel={t('common:email')}
              tabIndex={1}
              data-testid="signin-email-input"
              disabled={isLoading}
              autoFocus
            />
            <input
              type="password"
              style={{ display: 'none' }}
              autoComplete="current-password"
              onChange={(val) => dispatch(authActions.setAutocompletedPassword(val.target.value))}
            />

            <SubmitButton
              className={buttonStackStyles}
              label={t('continue')}
              tabIndex={2}
              isValid={isValid || (Object.values(dirtyFields).length === 1 && submitCount === 0)}
              isCheckmarkVisible={isCheckmarkVisible}
              isLoading={isLoading}
              data-testid="signin-email-submit-btn"
            />

            {featureFlags.socialLogin && (
              <Stack className={socialLoginStyles}>
                <Divider text={t('divider')} />
                <Stack tokens={{ childrenGap: themeSpacings.s }}>
                  <Stack.Item>
                    <SocialLoginButton
                      data-testid="signin-microsoft-sso-btn"
                      buttonType={IButtonType.Microsoft}
                      tabIndex={3}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <SocialLoginButton
                      data-testid="signin-google-sso-btn"
                      buttonType={IButtonType.Google}
                      tabIndex={4}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <SocialLoginButton data-testid="signin-apple-sso-btn" buttonType={IButtonType.Apple} tabIndex={5} />
                  </Stack.Item>
                </Stack>
              </Stack>
            )}
            <Stack.Item className={createAccountSectionStyles}>
              {featureFlags.createAccount
                ? splitAndStyleString(
                    t('newToTeamviewerLabel', {
                      link: `<>${t('createAccount')}</>`,
                    }),
                    /<>|<\/>/,
                    linkStyles,
                    '/register',
                    6,
                    '_self',
                    'signin-create-account',
                    isLoading,
                  )
                : splitAndStyleStringWithClickHandler(
                    t('newToTeamviewerLabel', {
                      link: `<>${t('createAccount')}</>`,
                    }),
                    /<>|<\/>/,
                    linkStyles,
                    () => {
                      toggleStayTunedDialog();
                    },
                    7,
                    'signin-create-account',
                    isLoading,
                  )}
            </Stack.Item>
          </Stack>
        </form>
      </ModalMain>
      <ModalBanner>
        <Banner.JustAStepAway />
      </ModalBanner>
    </Modal>
  );
};

function computeBackURL({ isNativeClient, searchParams }: { isNativeClient: boolean; searchParams: URLSearchParams }) {
  if (isNativeClient) {
    try {
      return `${new URL(searchParams.get('redirect_uri') ?? '').origin}/signin`;
    } catch {
      return '';
    }
  }
  return '';
}
