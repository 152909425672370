export const tryLocalStorage: <R extends StorageMemberReturnType>(callback: (localStorage: Storage) => R) => R | null =
  (callback) => tryStorage(() => localStorage, callback);

export const trySessionStorage: <R extends StorageMemberReturnType>(
  callback: (localStorage: Storage) => R,
) => R | null = (callback) => tryStorage(() => sessionStorage, callback);

export function tryStorage<R extends StorageMemberReturnType>(
  getStorage: () => Storage,
  callback: (store: Storage) => R,
) {
  try {
    return callback(getStorage());
  } catch (error) {
    if (error instanceof DOMException && error.name === 'SecurityError') {
      return null;
    }
    throw error;
  }
}

type StorageNoIdx = {
  [K in keyof Storage as string extends K ? never : K]: Storage[K];
};

type StorageMemberReturnType =
  | ReturnType<StorageNoIdx[keyof Omit<StorageNoIdx, 'length'>]>
  | /* length's member */ number;
