import { useTranslation } from 'react-i18next';
import type { ListChildComponentProps } from 'react-window';
import { VariableSizeList } from 'react-window';
import { IconButton, Modal } from '@teamviewer/ui-library';
import { chunk } from 'lodash';

import { useResponsive } from 'hooks';

import { licenseTextStyle, useIconButtonStyles } from './CopyrightModal.styles';

type Props = {
  isOpen: boolean;
  close: () => void;
};

const ROWS = chunk(COPYRIGHT_ATTRIBUTION.split('\n'), 10);
const Row = ({ index, style }: ListChildComponentProps) => <div style={style}>{ROWS[index]!.join('\n')}</div>;

const CopyrightModal = ({ isOpen, close }: Props) => {
  const { t } = useTranslation('common');
  const classes = useIconButtonStyles();
  const { screenSize } = useResponsive();
  const width = screenSize >= useResponsive.ScreenSize.Desktop ? 752 : 650;

  const getItemSize = (idx: number) => {
    const preElem = document.createElement('pre');

    preElem.style.visibility = 'hidden';
    preElem.style.width = `${width - 17}px`;
    preElem.style.whiteSpace = licenseTextStyle.whiteSpace;
    preElem.style.fontSize = licenseTextStyle.fontSize;
    preElem.style.fontFamily = licenseTextStyle.fontFamily;
    preElem.style.lineHeight = licenseTextStyle.lineHeight;

    preElem.textContent = ROWS[idx]!.join('\n');

    document.body.appendChild(preElem);
    const height = preElem.offsetHeight;
    document.body.removeChild(preElem);

    return height;
  };

  return (
    <div>
      <Modal
        titleAriaId="copyright-modal"
        isOpen={isOpen}
        onDismiss={close}
        isBlocking={false}
        containerClassName={classes.container}
      >
        <div className={classes.header} data-testid="footer-copyright-dialog">
          <h1>{t('copyright')}</h1>
          <IconButton
            className={classes.iconStyle}
            size="large"
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close popup modal"
            onClick={close}
            data-testid="footer-copyright-close-btn"
          />
        </div>
        <div className={classes.body}>
          <h2>{t('copyrightViewHeadline').toLocaleUpperCase()}</h2>
          <pre className={classes.licenseText}>
            <VariableSizeList height={700} itemCount={ROWS.length} itemSize={getItemSize} width={width}>
              {Row}
            </VariableSizeList>
          </pre>
        </div>
      </Modal>
    </div>
  );
};

export default CopyrightModal;
