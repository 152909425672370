import { makeStyles } from '@teamviewer/ui-library';

export const licenseTextStyle = {
  fontSize: '14px',
  whiteSpace: 'pre-wrap',
  fontFamily: 'sans-serif',
  lineHeight: '20px',
} as const;

export const useIconButtonStyles = makeStyles(
  ({ tokens }) =>
    ({
      container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        padding: '0px 24px 24px 24px',
        borderRadius: '16px',
        maxWidth: '900px',
        overflow: 'hidden',
        'div.ms-Modal-scrollableContent': {
          overflow: 'hidden',
        },
      },
      header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '64px',
        h1: {
          fontSize: tokens.typography.fontSizes.size20,
        },
      },
      body: {
        marginTop: '16px',
        h2: {
          fontSize: tokens.typography.fontSizes.size16,
        },
      },
      iconStyle: {
        '.ms-Button-flexContainer i': {
          fontSize: tokens.typography.fontSizes.size16,
        },
        width: '32px',
        height: '32px',
      },
      licenseText: {
        ...licenseTextStyle,
        overflow: 'auto',
      },
    }) as const,
);
