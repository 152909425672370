import { v4 as randomUUID } from 'uuid';

import type { AjaxResponse } from 'models/types/AjaxResponse';
import { tryLocalStorage } from './persistentStorage';

export function getBrowserId() {
  const storedBrowserId = localStorage.getItem('browserId');

  if (storedBrowserId) {
    return storedBrowserId;
  }

  const browserId = window.crypto.randomUUID?.() ?? randomUUID();

  tryLocalStorage((localStorage) => {
    localStorage.setItem('browserId', browserId);
  });

  return browserId;
}

export function mapToAjaxResponseObject(response: { [key: string]: any }): AjaxResponse {
  const ajaxResponse: AjaxResponse = {
    isSuccess: response.s,
  };

  if ('e' in response) {
    ajaxResponse.responseCode = response.e;
  }

  if ('ec' in response) {
    ajaxResponse.errorCode = response.ec;
  }

  if ('cat' in response) {
    ajaxResponse.responseCodeCategory = response.cat;
  }

  if ('m' in response) {
    ajaxResponse.message = response.m;
  }

  if ('t' in response) {
    ajaxResponse.responseCodeType = response.t;
  }

  if ('d' in response) {
    ajaxResponse.data = response.d;
  }

  if ('r' in response) {
    ajaxResponse.redirectUrl = response.r;
  }

  return ajaxResponse;
}
